import React from 'react'
import { Link } from 'react-router-dom'

const Home = () => {
  return (
    <div className="h-screen relative isolate overflow-hidden bg-gray-900">
    <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-balance text-4xl font-semibold tracking-tight text-white sm:text-5xl">
          Select a form to complete
        </h2>
        {/* <p className="mx-auto mt-6 max-w-xl text-pretty text-lg/8 text-gray-300">
          Incididunt sint fugiat pariatur cupidatat consectetur sit cillum anim id veniam aliqua proident excepteur
          commodo do ea.
        </p> */}
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Link
            to="/forms/677ff7127403ba992b4cb661"
            className="w-1/3 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
          >
            Tubing Waiver
          </Link>
        </div>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Link
            to="/forms/67884c0b3a08252b46be5acf"
            className="w-1/3 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
          >
            Lessons Waiver
          </Link>
        </div>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Link
            to="/forms/67888da03a08252b46be5ae1"
            className="w-1/3 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
          >
            Rental Form & Waiver
          </Link>
        </div>
      </div>
    </div>
    <svg
      viewBox="0 0 1024 1024"
      aria-hidden="true"
      className="absolute left-1/2 top-1/2 -z-10 size-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
    >
      <circle r={512} cx={512} cy={512} fill="url(#8d958450-c69f-4251-94bc-4e091a323369)" fillOpacity="0.7" />
      <defs>
        <radialGradient id="8d958450-c69f-4251-94bc-4e091a323369">
          <stop stopColor="#7775D6" />
          <stop offset={1} stopColor="#E935C1" />
        </radialGradient>
      </defs>
    </svg>
  </div>
  )
}

export default Home

import { ShareServiceClient } from '@azure/storage-file-share'

// Blob service configuration (store these securely)
const accountName = import.meta.env.VITE_FILES_SA_ACCOUNT_NAME;
const sasToken = import.meta.env.VITE_FILES_SA_SAS_TOKEN;
const shareName = import.meta.env.VITE_FILES_SA_SHARE_NAME;

const shareServiceClient = new ShareServiceClient(
  `https://${accountName}.file.core.windows.net/?${sasToken}`
);

const sanitizeFileName = (fileName) => {
  const maxFileNameLength = 255; // File name limit in Azure File Share
  const timestamp = new Date().getTime(); // Add a unique timestamp
  const cleanFileName = fileName.slice(-200); // Keep the last 200 characters of the file name
  const sanitizedFileName = `${timestamp}-${cleanFileName}`; // Combine timestamp with file name

  return sanitizedFileName.slice(0, maxFileNameLength); // Ensure final name is within limits
};

const getDirectoryClient = (shareName, directoryPath = "") => {
  const shareClient = shareServiceClient.getShareClient(shareName);
  return shareClient.getDirectoryClient(directoryPath);
};

export const uploadFileToAzureFileService = async (file, directoryPath = "") => {
  try {
    const directoryClient = getDirectoryClient(shareName, directoryPath);
    const fileName = sanitizeFileName(file.name);
    console.log(`File Name: ${fileName} (Length: ${fileName.length})`);
    console.log(`Share Name: ${shareName}`);

    // Get the file client for the specific file
    const fileClient = directoryClient.getFileClient(fileName);

    // Upload the file in chunks (required for larger files)
    const fileSize = file.size;
    //const uploadStream = file.stream();
    const stream = new ReadableStream({
      start(controller) {
        const reader = new FileReader();
        reader.onload = () => {
          controller.enqueue(new Uint8Array(reader.result));
          controller.close();
        };
        reader.onerror = (err) => controller.error(err);
        reader.readAsArrayBuffer(file);
      },
    });
    debugger
    await fileClient.uploadData(file)
    // await fileClient.uploadStream(stream, fileSize, 4 * 1024 * 1024, {
    //   onProgress: (progress) => {
    //     console.log(`Uploaded ${progress.loadedBytes} bytes`);
    //   },
    // });

    console.log("File uploaded successfully:", fileName);

    return fileClient.url; // Return the file URL
  } catch (error) {
    console.error("Error uploading file to Azure File Service:", error);
    throw error;
  }
};
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css'
import 'survey-core/defaultV2.min.css';
import MySurvey from './components/survey';
import DisplaySurvey from "./components/displaySurvey"
import DisplayForm from './components/displayForm'
import Home from "./components/home"

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/waiver" element={<Navigate to="/forms" replace />} />
        <Route path="/waiver/:schemaId" element={<Navigate to="/forms/:schemaId" replace />} />
        <Route path="/waiver/:schemaId/:formId" element={<Navigate to="/forms/:schemaId/:formId" replace />} />
        <Route path="/forms" element={<Home />} />
        <Route path="/forms/:schemaId" element={<MySurvey />} />
        <Route path="/forms/:schemaId/:formId" element={<DisplaySurvey />} />
        <Route path="/forms/form/:formId" element={<DisplayForm />} />
      </Routes>
    </Router>
  )
}

export default App

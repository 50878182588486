import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams, useLocation } from 'react-router-dom';
import { Model } from 'survey-core';
import surveyJson from '../models/mtbaldy2';
import { Survey } from 'survey-react-ui';
import { uploadFileToAzure } from '../services/blobStorage';
import { uploadFileToAzureFileService } from '../services/fileStorage';
import testData from '../assets/testform'
import theme from '../themes/mtbaldy'

const setPanels = (survey, maxMembers) => {

  const dynamicPanel = survey.getQuestionByName("members");
  if (dynamicPanel) {
    dynamicPanel.maxPanelCount = maxMembers; 
    console.log("Max Panel Count updated:", dynamicPanel.maxPanelCount);
  }
}

const setFields = (survey) => {
  console.log("Setting fields to not required")
  survey.getAllQuestions().forEach((question) => {
    question.isRequired = false;
  });
}

const MySurvey = () => {
  const navigate = useNavigate();
  const { schemaId } = useParams(); // Get schemaId from path
  const location = useLocation(); // Get query params
  const queryParams = new URLSearchParams(location.search);
  const passGroup = queryParams.get('passGroup') || undefined;
  const dev = queryParams.get('dev') || "0";
  const [formName, setFormName] = useState('')

  const [survey] = useState(new Model());
  const [maxMembers, setMaxMembers] = useState(1)
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const containerSasUrl = import.meta.env.VITE_GC_SA_SAS_TOKEN;

  if (!schemaId) {
    console.error("Missing schemaId in URL");
    alert("SchemaId is required")
    // navigate("/error"); // Handle missing schemaId
  }

  // Fetch schema data and configure survey
  useEffect(() => {
    const fetchSchema = async () => {
      try {
        //survey.applyTheme(theme)
        const schemaData = await fetch(`${import.meta.env.VITE_API_URL}/api/forms/schema/${schemaId}`)
        const json = await schemaData.json()
        survey.fromJSON(json.content)
        //console.log("Schema data: ", json)
        if (json.name) {
          setFormName(json.name)
        }

        if (dev == 1) {
          survey.data = testData
          setFields(survey)
        }
        survey.setValue("status", "New")
        //console.log("Schema Data Loaded:", schemaData);
      } catch (err) {
        console.log("Error fetching schema ", err)
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (schemaId) fetchSchema();
  }, [schemaId, survey]);

  // Fetch pass data and set survey values
  useEffect(() => {
    const fetchPass = async () => {
        try {
          const passData = await fetch(`${import.meta.env.VITE_API_URL}/api/waivers/${passGroup}`)
          const json = await passData.json()
          console.log("Pass Data Loaded:", json); 
          if (json.formId != "") {
            alert("This waiver has been completed.")
            //survey.setValue('passGroup', '')
            // survey.showCompleteButton = false
            // return
            navigate(`/forms/${schemaId}/${json.formId}`)
            return
          }
          
          if (json.passGroup) {
            survey.setValue("passGroup", json.passGroup);
          }
          
          if (json.maxMembers) {
            setMaxMembers(json.maxMembers)
            survey.setValue("maxMembers", json.maxMembers);
            setPanels(survey, json.maxMembers)
          }
          
        } catch (err) {
          console.log("Error fetching pass data", err)
          setError(err.message);
        }
      }

    if (passGroup) fetchPass();
  }, [passGroup, survey, maxMembers]);

  // Configure survey event handlers
  useEffect(() => {
    const sendResults = (sender, options) => {
      // console.log("Form data: ", sender.data)
      // console.log("formName: ", formName)
      //debugger
      const payload = {
        formSchemaId: schemaId,
        formSchema: sender.jsonObj,
        formContent: sender.data,
        formName: formName
      }
      console.log('Payload: ', payload)
      fetch(`${import.meta.env.VITE_API_URL}/api/forms`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
    };

    survey.onComplete.add(sendResults);

    return () => {
      survey.onComplete.remove(sendResults); // Clean up event handlers
    };
  }, [survey, formName]);

   // Configure survey event handlers
   useEffect(() => {
    const captureScreen = () => {
      console.log("....Upload screenshot here....")
    };

    survey.onComplete.add(captureScreen);

    return () => {
      survey.onComplete.remove(captureScreen); // Clean up event handlers
    };
  }, [survey]);
  

  //set/reset signature sign-on
  useEffect(() => {
    survey.onValueChanged.add((sender, options) => {
      
      if (options != undefined && options.question?.classMetaData.name != "signaturepad") {
        return
      }
      if (options.name.includes("-sd")) {
        // Check if signature field has been filled (i.e., it's not empty)
        if (options.value) {
          sender.setValue(`${options.name}-signed-on`, new Date().toUTCString());
        } else {
          // If signature is cleared, you can optionally clear the signed-on date
          sender.setValue(`${options.name}-signed-on`, null); // Clear the signed-on date if signature is removed
        }
      }
    });    
  }, [])

  // Handle file upload
  useEffect(() => {
    const uploadHandler = async (sender, options) => {
      const [file] = options.files;
      try {
        const uploadedUrl = await uploadFileToAzure(file, passGroup);
        //const uploadedUrl = await uploadFileToAzureFileService(file, '2425')
        
        options.callback(options.files.map((file)=> {
          return {
            file: file,
            content: uploadedUrl.split('?')[0]
          }
        })); // Pass the file URL back to SurveyJS
      } catch (error) {
        console.log("Error uploading file ", error)
      }
    };
  
    survey.onUploadFiles.add(uploadHandler);
  
    return () => {
      survey.onUploadFiles.remove(uploadHandler);
    };
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <Survey model={survey} />
    </div>
  );
};

export default MySurvey;
